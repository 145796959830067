import { ReactElement, useContext, useEffect, useState } from 'react';
import {
  useCategoriesDraft,
  useProjectConfig,
  useExternals,
  useChiefCategories,
  SubprojectContext,
} from '../../ContextProviders/AppContext';
import { Loading } from '../Loading/Loading';
import { PageWithSidebar } from '../PageTypes';
import { SettingsSidebar } from './SettingsSidebar';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { ErrorPage } from '../Error/ErrorPage';
import { sortByOrderIndex } from '../../../util/DataHelpers';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { IconButton } from '../../Buttons/Buttons';
import { Category, ChiefCategory, ExternalContent, IsChief } from '../../../Types';
import { AddCategoryButton } from './AddCategoryButton';
import AddCategory from './AddCategory';
import { useFirestore } from '../../ContextProviders/Firebase';
import { useConcreteProject, useProjectTitle } from '../../ContextProviders/ProjectContext';
import {
  categoryActions,
  iconActions,
  chiefCategoryFirebaseActions,
  externalActions,
} from '../../../Hooks/DatabaseActions';
import { toasts } from '../../../shared';
import './CategoriesPage.scss';

import { useAuth } from '../../ContextProviders/Auth';
import { toast } from 'react-toastify';
import { NewCategoryPayload } from '@eir/core';
import { CategoryTypes, en } from '../../../localizedStrings';
import { v4 as uuidV4 } from 'uuid';
import { LinkTypes, checkLinkType } from '../../Editor/LinkTypeChecker';

type CategoryWithChief = Category & IsChief;

export const CategoriesManager = (): ReactElement => {
  // Load project variables
  const localization = useLocalization();
  const projectConfig = useProjectConfig();

  // Load categories, externals and chief categories
  const { docs: categories, error, loading } = useCategoriesDraft();
  const { docs: externals, error: errorExternals, loading: loadingExternals } = useExternals();
  const { docs: chiefCategories, error: errorChief, loading: loadingChief } = useChiefCategories();
  const [indexedCategories, setCategories] = useState(
    [...categories, ...externals, ...chiefCategories].sort(sortByOrderIndex),
  );
  const hasChangelog = indexedCategories.some((category) => category.fId === 'changelog');

  // Firebase db and firebase storage
  const firestore = useFirestore();
  const project = useConcreteProject();
  const iActions = iconActions(project.firebase.project);
  const catActions = categoryActions(firestore, project.id);
  const chiefCatActions = chiefCategoryFirebaseActions(firestore, project.id);
  const extActions = externalActions(firestore, project.id);
  const activeSubProject = useContext(SubprojectContext);
  const auth = useAuth();

  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [baseUrl, setBaseUrl] = useState<string>();
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  // Visuals for popup.

  const [showSaveButton, setShowSaveButton] = useState(false);
  const [editingCategory, setEditingCategory] = useState<{
    index: number;
    category: Category | ExternalContent | ChiefCategory;
  } | null>(null);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [nestedModalOpen, setNestedModalOpen] = useState<boolean>(false);

  enum CategoryType {
    articleCategory = 'Standard Category',
    url = 'External link',
    html = 'Calculator',
    workorder = 'Forms',
    changelog = 'Changelog',
  }

  useEffect(() => {
    setCategories([...categories, ...externals, ...chiefCategories].sort(sortByOrderIndex));
  }, [categories, externals, chiefCategories, refreshTrigger]);

  useEffect(() => {
    if (project && project.firebase && project.firebase.project) {
      setBaseUrl(`https://europe-west3-${project.firebase.project}.cloudfunctions.net/SendEmail`);
      // Warmup the instance
      fetch(`https://europe-west3-${project.firebase.project}.cloudfunctions.net/SendEmail/warmer`, {
        method: 'GET',
      })
        .then(() => {
          /** */
        })
        .catch(() => {
          /** */
        });
    }
  }, [project]);

  const sendNotifyEmail = async (type: string, categoryName: string, categoryCost: string, categoryId?: string) => {
    if (!baseUrl) {
      toast.warn(localization.strings.project.notLoaded);
      return;
    }
    try {
      let recipientList = auth.user?.email;
      project.newCategoryEmailList.forEach((entry) => {
        recipientList += ';' + entry;
      });
      const date = new Date().toLocaleString('en-GB', { timeZone: 'UTC' });
      const projectName = activeSubProject && activeSubProject.id ? activeSubProject.name : project.fullName;
      const toc = en.category.termsOfUseAddCategory.replace('{{value}}', categoryCost);
      const emailBody = `<p>Hello,</p>
      <p>On the ${date}  a category was created in the project ${projectName}.</p>
      <p>Created by user: ${auth.user?.email} </p>
      <p>Category name: ${categoryName} </p>
      <p>Category ID: ${categoryId ?? categoryName.toLocaleLowerCase().split(' ').join('')} </p>
      <p>Category type: ${CategoryType[type]}</p>
      <p>Price: ${categoryCost} SEK / year</p>
      <br>
      <p>By doing this, user ${
        auth.user?.email
      } accepted the cost of ${categoryCost} SEK / year and Softwerk AB Terms and Conditions:</p>
      <p>${toc.split('\n\n').join('<br>')}</p>
      `;

      const url = `${baseUrl}/added-new-category`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: auth.user?.email,
          recipientList: recipientList,
          project: projectName,
          emailBody: emailBody,
        } as NewCategoryPayload),
      });
      if ((response && response.status === 200) || response.status === 201) {
        toasts.success(localization.strings.category.successCreatingCategory);
      }
    } catch (e) {
      toast.error(localization.strings.settings.emailError);
    }
  };

  // Handle drag and drop / changing the order of the categories
  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    e.dataTransfer.setData('index', index.toString());
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, newIndex: number) => {
    e.preventDefault();
    const oldIndex = parseInt(e.dataTransfer.getData('index'));
    const draggedCategory = indexedCategories[oldIndex];
    const updatedCategories = indexedCategories.filter((_, index) => index !== oldIndex);
    updatedCategories.splice(newIndex, 0, draggedCategory);
    setCategories(updatedCategories);
    setShowSaveButton(true);
  };

  const handleSaveSorting = async () => {
    toasts.info(localization.strings.category.sortingStarted);
    if (await catActions.customerSorting(indexedCategories)) {
      toasts.success(localization.strings.category.sortingCompleted);
    } else {
      toasts.error(localization.strings.category.sortingError);
    }
    setShowSaveButton(false);
  };

  // eslint-disable-next-line
  const beforeLinkInsert = (link: string, attrs: any, chiefCategories: CategoryWithChief[]) => {
    const isCategoryChief = (cid: string) => chiefCategories.some((c) => c.fId === cid);

    let finalLink;
    if (link.startsWith('#')) {
      finalLink = link;
      return { href: finalLink, attrs };
    }
    try {
      const url = new URL(link);
      const theLinkType = checkLinkType(url);

      if (theLinkType === LinkTypes.internalArticle) {
        const urlArray = url.pathname.split('/');
        const articleId = urlArray.pop();
        const categoryId = urlArray[urlArray.length - 1];

        const isChief: boolean = isCategoryChief(categoryId) ? true : false;
        const isChangelog: boolean = categoryId === 'changelog';
        if (isChief) {
          finalLink = `/chief-articles/${articleId}${url.hash}`;
        } else if (isChangelog) {
          finalLink = `/changelog-articles/${articleId}${url.hash}`;
        } else {
          finalLink = `/articles/${articleId}${url.hash}`;
        }
      } else if (theLinkType === LinkTypes.internalCategory) {
        const categoryId = url.pathname.split('/').pop();
        // eslint-disable-next-line
        const isChief: boolean = isCategoryChief(categoryId!) ? true : false;
        if (isChief) {
          finalLink = `/chief-categories/${categoryId}`;
        } else {
          finalLink = `/categories/${categoryId}`;
        }
      } else if (theLinkType === LinkTypes.internalAbout) {
        finalLink = `/about`;
      } else if (theLinkType === LinkTypes.internalExternal) {
        const categoryId = url.pathname.split('/').pop();
        finalLink = `/externals/${categoryId}${url.search}`;
      } else if (theLinkType === LinkTypes.internalPolicy) {
        const id = url.pathname.split('/').pop();
        finalLink = `/${id}`;
      } else if (theLinkType === LinkTypes.internalTerms) {
        const id = url.pathname.split('/').pop();
        finalLink = `/${id}`;
      } else if (theLinkType === LinkTypes.external) {
        finalLink = url;
      }

      return { href: finalLink, attrs };
    } catch (_) {
      return {
        href: link,
        attrs,
      };
    }
  };

  const handleSaveEdit = async (
    // Old category
    category: Category | ExternalContent | ChiefCategory,

    // Editable attributes
    newName: string,
    icon: File | null,
    availableMobile: boolean,
    availableWeb: boolean,
    url: string,
  ) => {
    toasts.info(localization.strings.category.editCategoryStarted);
    setApiLoading(true);
    try {
      const resourceUrl = icon != null ? await iActions.create(icon) : undefined;
      if (category?.isChief) {
        await chiefCatActions.edit(category, newName, resourceUrl, category.type, availableMobile, availableWeb, url);
        toasts.success(localization.strings.category.editCategorySucces);
      } else if (category.type === 'articleCategory' || category.type === 'changelog' || category.type === 'url') {
        if (url) {
          const newURL = beforeLinkInsert(url, '', chiefCategories);
          url = newURL.href.toString();
        }
        await catActions.edit(category, newName, resourceUrl, category.type, availableMobile, availableWeb, url);
        toasts.success(localization.strings.category.editCategorySucces);
      } else {
        await extActions.edit(category, newName, resourceUrl, availableMobile, availableWeb);
        toasts.success(localization.strings.category.editCategorySucces);
      }
    } catch (e) {
      toasts.error(localization.strings.category.editCategoryFailed);
    } finally {
      setApiLoading(false);
      setEditingCategory(null);
      setRefreshTrigger((old) => old + 1);
      closeAllModals();
    }
  };

  const handleSaveNewCategory = async (
    name: string,
    icon: File | null,
    type: CategoryTypes,
    availableMobile: boolean,
    availableWeb: boolean,
    adminOnly: boolean,
    url: string,
    cost: string,
  ) => {
    toasts.info(localization.strings.category.startedCreatingCategory);
    setApiLoading(true);
    try {
      const resourceUrl = icon != null ? await iActions.create(icon) : undefined;
      if (adminOnly) {
        if (await chiefCatActions.exists(name)) {
          toasts.error(localization.strings.category.categoryIdExists);
          return;
        } else {
          await chiefCatActions.create(
            name,
            resourceUrl,
            type,
            availableMobile,
            availableWeb,
            indexedCategories.length,
            url,
          );

          toasts.success(localization.strings.category.successCreatingCategory);
          if (project.firebase.project !== 'infosynk-sameffekt') {
            await sendNotifyEmail(type, name, cost);
          }
        }
      } else if (type === 'articleCategory' || type === 'changelog' || type === 'url' || type === 'nativeModule') {
        if (await catActions.exists(name, type)) {
          toasts.error(localization.strings.category.categoryIdExists);
          return;
        } else {
          if (url) {
            const newURL = beforeLinkInsert(url, '', chiefCategories);
            url = newURL.href.toString();
          }
          await catActions.create(
            name,
            resourceUrl,
            type,
            availableMobile,
            availableWeb,
            indexedCategories.length,
            url,
          );
          toasts.success(localization.strings.category.successCreatingCategory);
          if (project.firebase.project !== 'infosynk-sameffekt') {
            await sendNotifyEmail(type, name, cost);
          }
        }
      } else {
        if (await extActions.exists(name)) {
          toasts.error(localization.strings.category.categoryIdExists);
          return;
        } else {
          const categoryId = uuidV4();
          await extActions.create(
            name,
            resourceUrl,
            type,
            availableMobile,
            availableWeb,
            indexedCategories.length,
            categoryId,
          );
          toasts.success(localization.strings.category.successCreatingCategory);
          if (project.firebase.project !== 'infosynk-sameffekt') {
            await sendNotifyEmail(type, name, cost, categoryId);
          }
        }
      }
    } catch (e) {
      toasts.error(localization.strings.category.failedCreatingCategory);
    } finally {
      setApiLoading(false);
      setRefreshTrigger((old) => old + 1);
      closeAllModals();
    }
  };

  const title = useProjectTitle();
  useEffect(() => {
    document.title = `${localization.strings.settings.handleCategories} | ${title}`;
  }, [title, refreshTrigger, localization.strings.settings.handleCategories]);

  useEffect(() => {
    if (editingCategory) setModalOpen(true);
  }, [editingCategory]);

  const closeAllModals = () => {
    setModalOpen(false);
    setNestedModalOpen(false);
    setEditingCategory(null);
  };

  if (error) return <ErrorPage error={error} />;
  else if (errorExternals) return <ErrorPage error={errorExternals} />;
  else if (errorChief) return <ErrorPage error={errorChief} />;
  if (loading) return <Loading waitingFor={localization.strings.settings.categories} />;
  else if (loadingExternals) <Loading waitingFor={localization.strings.external.externalContent} />;
  else if (loadingChief) <Loading waitingFor={localization.strings.category.chiefLoading} />;

  if (projectConfig.loading) return <Loading waitingFor="Project Config" />;

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <h1>{localization.strings.settings.handleCategories}</h1>
          <hr />
          <div className="mainContent">
            <div className="categories-as-grid">
              {indexedCategories.map((category, index) => (
                <div
                  key={index}
                  className="category-item rounded shadow-sm acc-mng-module"
                  draggable="true"
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, index)}
                  onClick={() => setEditingCategory({ index, category })}
                >
                  <img className="icon" src={category.icon} alt="icon" />

                  <div className="name">{category.name}</div>
                  <div className="edit">
                    <IconButton
                      style={{ margin: '0 0 0 0.4rem', position: 'relative', background: 'transparent' }}
                      icon={faPen}
                    />
                  </div>
                </div>
              ))}
              <AddCategoryButton
                onClick={() => {
                  if (!editingCategory) {
                    setModalOpen(true);
                  }
                }}
                text={localization.strings.category.addCategory}
              />
            </div>
            {showSaveButton && (
              //eslint-disable-next-line @typescript-eslint/no-misused-promises
              <button className="save-update-index-button" onClick={handleSaveSorting}>
                {localization.strings.forms.saveSortingButton}
              </button>
            )}
          </div>

          <AddCategory
            category={editingCategory ? editingCategory.category : null}
            modalOpen={modalOpen}
            nestedModalOpen={nestedModalOpen}
            setModalOpen={setModalOpen}
            setNestedModalOpen={setNestedModalOpen}
            onSave={handleSaveNewCategory}
            onEdit={handleSaveEdit}
            hasChangelog={hasChangelog}
            closeAll={closeAllModals}
            loading={apiLoading}
          />
        </div>
      </main>
    </PageWithSidebar>
  );
};

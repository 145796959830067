import { useHistory, useParams } from 'react-router-dom';
import { useLocalization } from '../../../ContextProviders/LocalizationContext';
import { StaffRoleTire } from '@eir/core';
import { useDocument } from '../../../../Hooks';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useCategories, useExternals, useProjectConfig } from '../../../ContextProviders/AppContext';
import { ErrorPage } from '../../Error/ErrorPage';
import { Loading } from '../../Loading/Loading';
import { PageWithSidebar } from '../../PageTypes';
import { SettingsSidebar } from '../SettingsSidebar';
//import { useFirestore } from '../../../ContextProviders/Firebase';
import { Status, useAuth } from '../../../ContextProviders/Auth';
import { toast } from 'react-toastify';
import { FormFeedback, Input, Tooltip } from 'reactstrap';
import { CategorySelector } from '../CategoryLocker';
import { IconButton } from '../../../Buttons/Buttons';
import { faPlus, faSave, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useConcreteProject } from '../../../ContextProviders/ProjectContext';
import { WithID } from '../../../../Types';

interface Params {
  id?: string;
}
const EditRole = () => {
  const { id } = useParams<Params>();
  const willEdit = !!id;
  const { strings } = useLocalization();
  const projectConfig = useProjectConfig();

  const { doc, error, loading } = useDocument<StaffRoleTire & WithID>(id ? `/roles/${id}` : undefined, {});

  const title = useMemo(
    () => (willEdit ? strings.settings.roles.editRole : strings.settings.roles.createRole),
    [strings.settings.roles.createRole, strings.settings.roles.editRole, willEdit],
  );
  useEffect(() => {
    document.title = `${title} | ${projectConfig.doc.name}`;
  }, [projectConfig.doc.name, title]);

  const getContent = () => {
    if (id) {
      if (error) return <ErrorPage error={error} />;
      if (loading) return <Loading waitingFor={strings.settings.subscriptions.edit.title} />;
      return <EditRoleContent type="edit" doc={doc} />;
    } else return <EditRoleContent type="create" />;
  };
  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <h1>{title}</h1>
          <hr />
          {getContent()}
        </div>
      </main>
    </PageWithSidebar>
  );
};

type Props =
  | {
      type: 'edit';
      doc: StaffRoleTire & WithID;
    }
  | { type: 'create' };

const EditRoleContent: FC<Props> = (props) => {
  const { strings } = useLocalization();
  const history = useHistory();
  const project = useConcreteProject();
  const auth = useAuth();
  const config = useProjectConfig();
  const { docs: categories } = useCategories();
  const { docs: externals } = useExternals();
  //const firestore = useFirestore();
  const [requestStatus, setRequestStatus] = useState(Status.IDLE);
  const [name, setName] = useState(props.type === 'edit' ? props.doc.name : '');
  const [roleNameErrorMessage, setRoleNameErrorMessage] = useState<boolean>(false);
  const [categoryPermissions, setCategoryPermissions] = useState(
    props.type === 'edit' ? props.doc.categoryPermissions ?? [] : [],
  );
  const [hasWorkOrderAccess, setHasWorkOrderAccess] = useState<boolean>(
    props.type === 'edit' ? props.doc.hasWorkOrderAccess : false,
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [baseUrl, setBaseUrl] = useState<string>();

  const [isSubprojectAdmin, setIsSubprojectAdmin] = useState<boolean>(
    props.type === 'edit' ? props.doc.isSubprojectAdmin : false,
  );

  const subprojectAdminCategories = config.doc.categoryEditPermissions ?? [];
  const allCategories = [...categories, ...externals];
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const isValidString = (input: string): boolean => {
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9_-]*[a-zA-Z0-9]$/;
    return regex.test(input);
  };

  const handleCreate = async (): Promise<void> => {
    if (!baseUrl) {
      toast.warn(strings.project.notLoaded);
      return;
    }
    if (!isValidString(name)) {
      setRoleNameErrorMessage(true);
      return;
    }
    setRequestStatus(Status.IN_FLIGHT);
    try {
      const url = `${baseUrl}/create-role`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          categoryPermissions,
          hasWorkOrderAccess,
          isSubprojectAdmin,
          projectId: project.id,
        } as StaffRoleTire),
      });
      if (response.ok) {
        toast.success(strings.settings.roles.createRoleSuccess);
        history.push('/settings/role-configuration');
      } else {
        toast.error(strings.settings.roles.createRoleError);
      }
    } catch (e) {
      toast.error(strings.settings.roles.createRoleError);
    } finally {
      setRequestStatus(Status.IDLE);
      setRoleNameErrorMessage(false);
      setName('');
      setCategoryPermissions([]);
      setHasWorkOrderAccess(false);
    }
  };

  const handleEdit = async () => {
    if (!baseUrl || props.type !== 'edit') {
      toast.warn(strings.project.notLoaded);
      return;
    }
    if (!isValidString(name)) {
      setRoleNameErrorMessage(true);
      return;
    }
    setRequestStatus(Status.IN_FLIGHT);
    try {
      const url = `${baseUrl}/update-role/${props.doc.fId}`;
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name,
          categoryPermissions,
          hasWorkOrderAccess,
          isSubprojectAdmin,
          projectId: project.id,
        } as StaffRoleTire),
      });
      if (response.ok) {
        toast.success(strings.settings.roles.editRole);
      } else {
        toast.error(strings.settings.roles.editRole);
      }
    } catch (e) {
      toast.error(strings.settings.roles.editRole);
    } finally {
      setRequestStatus(Status.IDLE);
      history.goBack();
    }
  };

  useEffect(() => {
    if (project && project.firebase && project.firebase.project) {
      setBaseUrl(`https://europe-west3-${project.firebase.project}.cloudfunctions.net/MultiAccountApi`);
      // Warmup the instance
      fetch(`https://europe-west3-${project.firebase.project}.cloudfunctions.net/MultiAccountApi/warmer`, {
        method: 'GET',
      })
        .then(() => {
          /** */
        })
        .catch(() => {
          /** */
        });
    }
  }, [project]);

  const isLoading = requestStatus === Status.IN_FLIGHT;
  const isChanged =
    props.type === 'edit' &&
    (name !== props.doc.name ||
      !areArraysEqual(categoryPermissions, props.doc.categoryPermissions) ||
      hasWorkOrderAccess !== props.doc.hasWorkOrderAccess ||
      isSubprojectAdmin !== props.doc.isSubprojectAdmin);

  return (
    <div>
      <div className="acc-mng-module shadow-sm">
        <h6>{strings.settings.roles.roleName}</h6>
        <Tooltip isOpen={tooltipOpen} target="TooltipExample" toggle={toggle} style={{ minWidth: '30rem' }}>
          <p>{strings.settings.roles.roleNameFormat.line1}</p>
          <p>{strings.settings.roles.roleNameFormat.line2}</p>
          <p>{strings.settings.roles.roleNameFormat.line3}</p>
        </Tooltip>

        <Input
          rel="noreferrer"
          id="TooltipExample"
          type="text"
          placeholder={strings.settings.roles.roleName}
          disabled={isLoading}
          value={name}
          onChange={({ target: { value } }) => {
            setName(value);
            setRoleNameErrorMessage(false);
          }}
          invalid={roleNameErrorMessage}
        />

        <FormFeedback>{strings.settings.roles.roleNameError}</FormFeedback>
      </div>
      {!auth.isSubprojectAdmin && (
        <div className="acc-mng-module shadow-sm">
          <h5>{strings.settings.roles.workOrdersTitle}</h5>
          <div className="categories">
            <Fragment>
              <div className="name">
                <div>{strings.settings.roles.workOrdersSubTitle}</div>
              </div>
              <div className="toggle">
                <CategorySelector
                  canRead={hasWorkOrderAccess}
                  isDisabled={isLoading}
                  isLoading={false}
                  onToggle={() => setHasWorkOrderAccess((prevValues) => !prevValues)}
                />
              </div>
            </Fragment>
          </div>
        </div>
      )}

      {!auth.isSubprojectAdmin && (
        <div className="acc-mng-module shadow-sm">
          <h5>{'Subproject Admin'}</h5>
          <div className="categories">
            <Fragment>
              <div className="name">
                <div>{'Give subproject admin permissions'}</div>
              </div>
              <div className="toggle">
                <CategorySelector
                  canRead={isSubprojectAdmin}
                  isDisabled={isLoading}
                  isLoading={false}
                  onToggle={() => setIsSubprojectAdmin((prevValues) => !prevValues)}
                />
              </div>
            </Fragment>
          </div>
        </div>
      )}
      <div className="acc-mng-module shadow-sm">
        <h5>{strings.settings.roles.categoriesTitle}</h5>
        <div className="categories">
          {allCategories.length > 0
            ? allCategories
                .filter((cat) => (auth.isSubprojectAdmin ? subprojectAdminCategories?.includes(cat.fId) : cat))
                .map((c) => {
                  if (c.isProtected === true) {
                    return (
                      <Fragment key={c.fId}>
                        {'content' in c ? (
                          <div className="name-with-sub">
                            <div>{c.name}</div>
                            {'content' in c && <div className="sub">{strings.external.externalContent}</div>}
                          </div>
                        ) : (
                          <div className="name">
                            <div>{c.name}</div>
                          </div>
                        )}
                        <div className="toggle">
                          <CategorySelector
                            canRead={categoryPermissions.includes(c.fId)}
                            isDisabled={isLoading}
                            isLoading={false}
                            onToggle={() =>
                              setCategoryPermissions((prevValues) =>
                                prevValues.includes(c.fId)
                                  ? prevValues.filter((item) => item !== c.fId)
                                  : [...prevValues, c.fId],
                              )
                            }
                          />
                        </div>
                      </Fragment>
                    );
                  } else {
                    return '';
                  }
                })
            : strings.settings.staffAccountCategoriesPlaceholder}
        </div>
      </div>

      {props.type === 'create' ? (
        <IconButton
          theme="dark"
          onClick={handleCreate}
          disabled={isLoading || !name || !!roleNameErrorMessage}
          isLoading={isLoading}
          icon={faPlus}
          text={strings.settings.roles.createRole}
        />
      ) : (
        <>
          <IconButton
            theme="dark"
            disabled={isLoading || !name || !!roleNameErrorMessage || !isChanged}
            isLoading={isLoading}
            onClick={handleEdit}
            icon={faSave}
            text={strings.global.save}
          />
          <IconButton
            theme="dark"
            disabled={isLoading}
            onClick={() => history.goBack()}
            icon={faTimes}
            text={strings.global.cancel}
          />
        </>
      )}
    </div>
  );
};

export default EditRole;

// Helper function to compare arrays
function areArraysEqual(a: string[], b: string[]) {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

import { InvitationStatus, StaffRoleTire, UserRole } from '@eir/core';
import React, { useCallback, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Table } from 'reactstrap';
import { useLocalization } from '../../../ContextProviders/LocalizationContext';
import { format } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { IconButton } from '../../../Buttons/Buttons';
import { faEnvelope, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { CMSUser, WithID } from '../../../../Types';
import { useCategories } from '../../../ContextProviders/AppContext';
import { useAuth } from '../../../ContextProviders/Auth';

interface Column {
  label: string;
  accessor: keyof CMSUser | 'select' | 'buttons';
  sortFunction?: (a: CMSUser, b: CMSUser) => number;
}

type Props = {
  data: CMSUser[];
  setData: React.Dispatch<React.SetStateAction<CMSUser[]>>;
  selectedRows: CMSUser[];
  setSelectedRows: React.Dispatch<React.SetStateAction<CMSUser[]>>;
  deleteUser: (user?: CMSUser) => Promise<void>;
  resendInvitation: (user?: CMSUser) => Promise<void>;
  loading: boolean;
  changeRole: (user: CMSUser) => Promise<void>;
  roleTires: (StaffRoleTire & WithID & Record<string, never>)[];
};

const SortableTable: React.FC<Props> = (props: Props) => {
  const localization = useLocalization();
  const categories = useCategories();
  const auth = useAuth();

  const [sortConfig, setSortConfig] = useState<{
    key: keyof CMSUser;
    direction: 'ascending' | 'descending';
  } | null>(null);

  const [dropdownOpen, setDropdownOpen] = useState<number>();
  const toggle = (index: number) => setDropdownOpen((prevState) => (prevState !== index ? index : undefined));

  const sortData = (key: keyof CMSUser) => {
    const direction = sortConfig?.key === key && sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
    const sortedData = [...props.data].sort((a, b) => {
      const valA = a[key];
      const valB = b[key];

      if (valA === null || valA === undefined) {
        return 1; // Consider null/undefined as greater (or place at the end)
      }
      if (valB === null || valB === undefined) {
        return -1; // Consider null/undefined as less (or place at the beginning)
      }
      if (valA < valB) return -1;
      if (valA > valB) return 1;
      return 0;
    });

    if (direction === 'descending') {
      sortedData.reverse();
    }

    props.setData(sortedData);
    setSortConfig({ key, direction });
  };

  const columns: Column[] = [
    { label: '', accessor: 'select' },
    {
      label: localization.strings.settings.multiStaffAccount.displayName,
      accessor: 'displayName',
      sortFunction: (a, b) => a.displayName.localeCompare(b.displayName),
    },
    {
      label: localization.strings.settings.multiStaffAccount.email,
      accessor: 'email',
      sortFunction: (a, b) => a.email.localeCompare(b.email),
    },
    {
      label: localization.strings.settings.multiStaffAccount.projectId,
      accessor: 'projectId',
      sortFunction: (a, b) => (a.projectId && b.projectId ? a.projectId.localeCompare(b.projectId) : 0),
    },
    {
      label: localization.strings.settings.multiStaffAccount.role,
      accessor: 'role',
      sortFunction: (a, b) => a.role.localeCompare(b.role),
    },
    {
      label: localization.strings.settings.multiStaffAccount.createdAt,
      accessor: 'createdOn',
      sortFunction: (a, b) =>
        a.createdOn && b.createdOn
          ? (a.createdOn as Timestamp).toDate().getTime() - (b.createdOn as Timestamp).toDate().getTime()
          : 0,
    },
    {
      label: localization.strings.settings.multiStaffAccount.status,
      accessor: 'status',
      sortFunction: (a, b) => (a.status && b.status ? a.status.localeCompare(b.status) : 0),
    },
    { label: '', accessor: 'buttons' },
  ];

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      if (auth.isSubprojectAdmin) {
        props.setSelectedRows([...props.data.filter((u) => u.role !== UserRole.ADMIN)]);
      } else {
        props.setSelectedRows([...props.data]);
      }
    } else {
      props.setSelectedRows([]);
    }
  };

  const handleRowSelect = (user: CMSUser) => {
    if (props.selectedRows.includes(user)) {
      props.setSelectedRows(props.selectedRows.filter((i) => i !== user));
    } else {
      props.setSelectedRows([...props.selectedRows, user]);
    }
  };

  type RoleNamesType = {
    name: string;
    fId: string;
    isSubprojectAdmin: boolean;
  }[];
  const generateDropDownItems = useCallback(
    (user: CMSUser) => {
      // Start with the ADMIN role
      let roleNames: RoleNamesType = [{ name: `${UserRole.ADMIN}`, fId: '0', isSubprojectAdmin: false }];
      // Concatenate staffRoleTires to roleNames and update roleNames with the result
      roleNames = roleNames.concat(
        props.roleTires.map((role, i) => ({
          name: role.name,
          index: i + 1,
          isSubprojectAdmin: role.isSubprojectAdmin,
          fId: role.fId,
        })),
      );

      return (
        <>
          <DropdownToggle caret size="sm">
            {user.roleTire ? roleNames.find((rn) => rn.fId === user.roleTire)?.name : user.role}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>{localization.strings.settings.multiStaffAccount.changeRole}</DropdownItem>
            {roleNames
              .filter((rn) =>
                auth.isSubprojectAdmin
                  ? rn.name !== user.role && rn.fId !== user.roleTire && rn.fId !== '0'
                  : rn.name !== user.role && rn.fId !== user.roleTire,
              )
              .map((r) => (
                <DropdownItem
                  key={r.fId}
                  onClick={() => {
                    if (r.fId === '0') {
                      void props.changeRole({
                        ...user,
                        role: UserRole.ADMIN,
                        roleTire: null,
                        categoryPermissions: [...categories.docs].map((c) => c.fId),
                      });
                    } else {
                      const _role = props.roleTires.find((rn) => rn.fId === r.fId);
                      void props.changeRole({
                        ...user,
                        role: r.isSubprojectAdmin ? UserRole.SUBPROJECT_ADMIN : UserRole.STAFF,
                        roleTire: r.fId,
                        categoryPermissions: _role ? _role.categoryPermissions : [],
                      });
                    }
                  }}
                >
                  {r.name}
                </DropdownItem>
              ))}
          </DropdownMenu>
        </>
      );
    },
    [categories.docs, localization.strings.settings.multiStaffAccount.changeRole, props, auth.isSubprojectAdmin],
  );

  return (
    <div style={{ border: '0.5px solid lightGrey', borderRadius: '5px', minHeight: '50vh' }}>
      <Table>
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.accessor}
                onClick={() =>
                  column.accessor !== 'select' && column.accessor !== 'buttons' && sortData(column.accessor)
                }
              >
                {column.accessor === 'select' ? (
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={
                      auth.isSubprojectAdmin
                        ? props.selectedRows.length === props.data.filter((u) => u.role !== UserRole.ADMIN).length &&
                          props.data.filter((u) => u.role !== UserRole.ADMIN).length > 0
                        : props.selectedRows.length === props.data.length && props.data.length > 0
                    }
                  />
                ) : column.accessor === 'buttons' ? null : (
                  <>
                    {column.label}
                    <span className="ml-2">&#9660;</span>
                  </>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.data.map((d, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  disabled={auth.isSubprojectAdmin && d.role === UserRole.ADMIN}
                  onChange={() => handleRowSelect(d)}
                  checked={props.selectedRows.includes(d)}
                />
              </td>
              <td>{d.displayName}</td>
              <td>{d.email}</td>
              <td>{d.projectId}</td>
              <td>
                <Dropdown
                  isOpen={dropdownOpen === index}
                  toggle={() => toggle(index)}
                  disabled={props.loading || (auth.isSubprojectAdmin && d.role === UserRole.ADMIN)}
                  key={`dropdown-${index}`}
                >
                  {generateDropDownItems(d)}
                </Dropdown>
              </td>
              <td>{d.createdOn && format((d.createdOn as Timestamp).toDate(), 'yyyy-MM-dd  HH:mm')}</td>
              <td>{d.status}</td>
              <td>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <IconButton
                    isLoading={props.loading}
                    disabled={props.loading || auth.isSubprojectAdmin}
                    size="sm"
                    icon={faTrash}
                    color="danger"
                    onClick={() => {
                      void props.deleteUser(d);
                    }}
                  />
                  {d.status === InvitationStatus.PENDING && (
                    <IconButton
                      isLoading={props.loading}
                      disabled={props.loading}
                      size="sm"
                      icon={faEnvelope}
                      color="info"
                      onClick={() => {
                        void props.resendInvitation(d);
                      }}
                    />
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default SortableTable;
